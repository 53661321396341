@import 'webresources-sources/scss/imports';

.accordion-component {
  .accordion-area {
    border-top: 1px solid $color-gray-600;
    border-bottom: 1px solid $color-gray-600;

    .card {
      border-bottom: 1px solid $color-gray-600;

      .card-header {
        &:hover {
          .card-headline {
            color: var(--accent-hover-color);

            &::after {
              background-color: var(--accent-hover-color);
            }
          }
        }
      }

      .card-content {
        &.show {
          .card-body {
            &::after {
              /* background: $color-skyblue-600; */
            }
          }
        }
      }
    }
  }

  .text-component {
    width: 100% !important;
  }
}
