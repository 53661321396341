/* bootstrap overwrite */
.accordion-component .accordion-area {
  border-top: 1px solid #657785;
  border-bottom: 1px solid #657785;
}
.accordion-component .accordion-area .card {
  border-bottom: 1px solid #657785;
}
.accordion-component .accordion-area .card .card-header:hover .card-headline {
  color: var(--accent-hover-color);
}
.accordion-component .accordion-area .card .card-header:hover .card-headline::after {
  background-color: var(--accent-hover-color);
}
.accordion-component .accordion-area .card .card-content.show .card-body::after {
  /* background: $color-skyblue-600; */
}
.accordion-component .text-component {
  width: 100% !important;
}